@import url('https://fonts.googleapis.com/css?family=Radio+Canada&display=swap');

$head_title_font: Radio Canada, sans-serif;
$head_title_size: 42px;
$head_title_weight: 700;
$head_title_spacing: 0px;

.head{
	margin: 0 auto;
	padding: 100px 0 50px 0;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;

	@include breakpoint( $breakpoint_s ) {
		padding: 40px 0 0px 0;
		flex-direction: column;
		align-items: center;
	}

	@include breakpoint-h( $breakpoint_s ) {
		padding: 0;
	}

	&__logo{
		margin: 0 0 40px 0;
		max-height: 72px;

		@include breakpoint( $breakpoint_m ) {
			// margin: 0 40px 40px 80px;
		}
		@include breakpoint( $breakpoint_s ) {
			// margin: 0 0 40px 0;
			max-height: 100px;
		}
	}

	&__copy{

		@include breakpoint( $breakpoint_s ) {
			text-align: center;
		}

		h1{
			margin: 0;
			font-size: 60px;
			line-height: $head_title_size;
			font-family: $head_title_font;
			font-weight: $head_title_weight; 
			letter-spacing: $head_title_spacing;
			color: #222;
			text-transform: uppercase;

			@include breakpoint( $breakpoint_m ) {
				font-size: 50px;
			}
		}

		h3{
			display: block;
			margin: 15px 0 0 4px; 
			font-size: 10px;
			font-weight: 900;
			color: #222;
			letter-spacing: 8.4px;
			text-transform: uppercase;

			@include breakpoint( $breakpoint_m ) {
				margin: 15px 0 0 5px; 
				letter-spacing: 5.8px;
			}
		}
	}
}