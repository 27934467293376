$hello_info_width_desktop: 30vw;
$hello_video_width_desktop: 70vw;

.page--hello{
	background-image: url( 'https://www.prijaton.com/img/posts.png?cb=123');
	background-size: cover;
	background-position: center center;
	width: 100vw;
	height: 100vh;

	display: flex;
	flex-direction: column;
	justify-content: space-between;

	.hello{

		display: flex;
		flex-direction: row;

		@include breakpoint( $breakpoint_m ) {
			flex-direction: column;
		}

		// left panel
		&__left{
			width: $hello_info_width_desktop;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			align-items: flex-start;
			min-height: 100vh;

			@include breakpoint( $breakpoint_l ) {
				width: 100vw;
				min-height: 100vh;
			}

			@include breakpoint( $breakpoint_m ) {
				justify-content: space-around;
			}

			@include breakpoint-h( $breakpoint_s ) {
				justify-content: space-around;
			}
		}

		&__links{
			margin: 50px auto 100px auto;
			display: flex;
			flex-direction: column;
			align-items: flex-start;

			@include breakpoint( $breakpoint_m ) {
				margin: 0 auto 120px auto;
			}

			@include breakpoint-h( $breakpoint_s ) {
				flex-direction: row;
				justify-content: space-around;
				margin: 0;
			}
		}
		&__link{
			display: block;
			margin: 5px 0 0 0;
			font-size: 50px; 
			line-height: 50px;
			letter-spacing: 0;
			font-weight: 700;
			text-decoration: none;
			border: 0;
			color: $color_dark;
			cursor: pointer;
			transition: all .1s ease-in-out;
			text-transform: uppercase;

			&:hover{
				transform: translateX(5px);
			}

			&--accent{
				position: relative;
				font-weight: 900;
				color: $color_area;
			}
		}

		&__scroll{
			position: absolute;
			z-index: 97;
			bottom: 0px;
			left: 0;
			right: 0;
			display: none;
			text-align: center;

			&-wrapper{
				display: inline-block;
				padding: 30px 30px 25px 30px;
				font-size: 10px;
				font-weight: 900;
				color: $color_light;
				background: $color_area;
				letter-spacing: 6.5px;
				text-transform: uppercase;
				text-align: center;
				pointer-events: none;
			}

			@include breakpoint( $breakpoint_m ) {
				display: block;
			}
		}

		// right video panel
		&__video{
			position: relative;
			width: $hello_video_width_desktop;
			height: 100vh;
			overflow: hidden;

			@include breakpoint( $breakpoint_l ) {
				width: 100vw;
			}

			&-info{
				position: absolute;
				z-index: 98;
				bottom: 0px;
				right: 0;
				width: calc( #{$hello_video_width_desktop * 0.5} - 100px );
				// height: $hello_info_width_desktop * .25;
				padding: 40px 30px;
				background: rgba($color_light, 0.99);
				color: $color_area;
				text-align: center;
				// border-radius: 25px 0 0 0;

				&:after{
					content: '';
					width: 100px;
					height: 100px;
					background: #f00;
					-webkit-mask-image: radial-gradient(circle 10px at 0 0, transparent 0, transparent 20px, black 21px);
				}


				@include breakpoint( $breakpoint_m ) {
					width: auto;
					padding: 30px 20px;
					left: 0;
				}

				@include breakpoint-h( $breakpoint_s ) {
					width: calc( 40vw - 40px );
					padding: 20px 10px;
					right: 0px;
				}

				label{
					display: block;
					margin: 0 0 20px 0; 
					font-size: 11px;
					font-weight: 700;
					color: $color_dark;
					letter-spacing: 6.5px;
					text-transform: uppercase;

					@include breakpoint( $breakpoint_m ) {
						display: none;
					}
					@include breakpoint-h( $breakpoint_s ) {
						margin: 0 0 10px 0;
					}
				}

				&-wrapper{
					display: flex;
					justify-content: center;
					align-items: center;
				}

				&-marker{
					display: none;
					margin: 0 15px 0 0;
					height: 32px;
					vertical-align: -8px;
				}

				&-title{
					display: inline-block;
					color: $color_area;
					font-size: 20px;
					line-height: 21px;
					letter-spacing: 1px;
					font-weight: 900;
					transition: all .1s ease-in-out;
					text-decoration: underline;

					&:hover{
						transform: translateX(5px);
						color: $color_area;
					}
				}

				&-toggler{
					// display: inline-block;
					width: 20px;
					height: 20px;
					margin: 0px 0 0 15px;
					padding: 10px 0 3px 0;
					font-size: 18px;
					font-weight: 900;
					cursor: pointer;

					display: flex;
	
					svg{
						rect{
							stroke: $color_area; 
							fill: $color_area;
						}
					}
	
					&:not(.muted){
						
					}
					&.muted{
						
					}
				}
			}

			video{
				object-fit: cover;
				width: 100%;
    			height: 100%;
			}

			img{
				object-fit: cover;
				width: 100%;
    			height: 100%;

				animation: 300s ease 0s normal none infinite running zoomin;
			}
		}

	}
}

@keyframes zoomin {
    0% { transform: scale(1.2, 1.2) }
    50% { transform: scale(1, 1) }
    100% { transform: scale(1.2, 1.2) }
}