html{
	scroll-behavior: smooth;
}

body {
	width: 100vw;
	height: 100vh;
	margin: 0;
	padding: 0;

	background: $color_light;

	-webkit-tap-highlight-color: transparent;
}


.main{
	width: 100vw;
	height: 100vh;
}

.page{
	&__back{
		display: flex;
		align-items: center;

		margin: 50px 0 0 0;
		font-size: 50px;
		font-weight: 700;
		text-decoration: none;
		color: $color_dark;
		cursor: pointer;
		text-transform: uppercase;

		@include breakpoint( $breakpoint_m ) {
			font-size: 40px;
		}

		strong{
			margin: 0 20px 0 0;
			font-size: 50px;
			color: $color_area;
			cursor: pointer;
			transition: all .1s ease-in-out;

			@include breakpoint( $breakpoint_m ) {
				margin: 0 10px 0 0;
				font-size: 45px;
				line-height: 40px;
			}

			&:hover{
				transform: translateX(-5px);
			}
		}
	}
}
  

.animate-blink{
	transition: all .2s ease-in-out;
	animation: blink 1s infinite;
	-webkit-animation: blink 1s infinite;
}
@keyframes blink {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}
@-webkit-keyframes blink {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}