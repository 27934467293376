.page--support{
	background-image: url( 'https://www.prijaton.com/img/posts.png');
	background-size: cover;
	background-position: center center;
	width: 100%;
	height: 100vh;
	overflow-y: auto;

	.support{
		margin: 50px 50px;
		padding: 5px 15px; 
		color: $color_dark;

		width: 400px;
		@include breakpoint( $breakpoint_m ) {
			width: 50vw;
		}
		@include breakpoint( $breakpoint_s ) {
			margin: 20px;
			width: calc( 100vw - 70px );
		}

		&__head{
			margin: 50px 0 50px 0;
			font-size: 20px;
			line-height: 28px;
		}

		&__content{
			display: flex;
            align-items: center;

            font-size: 20px;
            font-weight: 700;

			@include breakpoint( $breakpoint_m ) {
				flex-direction: column;
                text-align: center;
			}

            

            a{
                display: inline-block;
                margin: 0 0 0 20px;
				padding: 30px 30px;
				font-size: 10px;
				font-weight: 900;
				color: $color_light;
				background: $color_area;
				letter-spacing: 6.5px;
				text-transform: uppercase;
				text-align: center;
				transition: all .2s ease-in-out;

				&:hover{
					background: darken( $color_area, 10% );
				}


                @include breakpoint( $breakpoint_m ) {
                    margin: 10px 0 0 0;
                    width: calc( 100% - 60px );
                }
            }

			
		}

		&__newsletter{
			margin: 0;
			display: block;
			color: $color_dark;

			iframe{
				display: block;
				margin: 0 -20px;
				padding: 0;
				width: 450px !important;
				height: 450px !important;
				background: transparent;
				border: none;

				@include breakpoint( $breakpoint_l ) {
					width: calc(100% + 40px) !important;
					height: 500px !important;
				}

				@include breakpoint( $breakpoint_m ) {
					// margin: 0 0px;
				}
			}
		}
	}
}