.page--posts{
	background-image: url( 'https://www.prijaton.com/img/posts.png');
	background-size: cover;
	background-position: center center;
	width: 100%;
	height: 100vh;
	overflow-y: auto;

	.posts{

		margin: 50px 50px 0 50px;
		padding: 5px 15px; 
		font-size: 13px; 
		line-height: 21px;
		color: $color_dark;

		width: 33vw;

		@include breakpoint( $breakpoint_m ) {
			width: 66vw;
		}

		@include breakpoint( $breakpoint_s ) {
			width: calc( 100vw - 130px );
		}

		&__head{
			margin: 50px 0 50px 0;
			font-size: 20px;
			line-height: 28px;
		}

		&__container{
			margin: 50px 0 0px 0;
			display: flex;
			flex-direction: column;
		}

		.post{
			margin: 0 0 50px 0;

			// title
			h2{
				margin: 10px 0 0 0;
				font-weight: 400;
				font-size: 28px;
				line-height: 36px;
				color: $color_dark; 
				text-decoration: none;
			}

			// time date
			h3{
				margin: 10px 0 0 0;
				font-weight: 400;
				font-size: 12px;
				color: $color_dark;
				text-decoration: none;
			}

			// subtitle
			h4{
				margin: 5px 0 0 0;
				font-weight: 700;
				font-size: 26px;
				line-height: 32px;
				color: $color_dark;
				text-decoration: none;
				text-transform: uppercase;
			}

			&__description{
				margin: 10px 0;
				font-size: 16px;
				line-height: 25px;
			}

			// links
			a{
				text-decoration: underline;
				font-weight: 600;
			}
		}

		.newsletter{
			margin: 0;
			display: block;
			color: $color_dark;
	
			iframe{
				display: block;
				margin: 0 -20px;
				padding: 0;
				width: 450px !important;
				height: 450px !important;
				background: transparent;
				border: none;
	
				@include breakpoint( $breakpoint_l ) {
					width: calc(100% + 40px) !important;
					height: 500px !important;
				}
	
				@include breakpoint( $breakpoint_m ) {
					// margin: 0 0px;
				}
			}
		}
	}

	

}