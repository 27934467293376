.page--error{
	background-image: url( 'https://www.prijaton.com/img/posts.png');
	background-size: cover;
	background-position: center center;
	width: 100%;
	height: 100vh;
	overflow-y: auto;

	.error{
		margin: 25px 50px;
		padding: 5px 15px; 
		width: 100vw; 
		color: $color_dark;

		h2{
			margin: 0;
			font-size: 60px;
		}
	}

}