// colors
$color_area: #306CC7;
$color_location: #FFC500;
$color_device: #FF8733;

$color_messages: #FFC500;

$color_dark: #222;
$color_light: #fff;


// breakpoints
$breakpoint_s: 500px;
$breakpoint_m: 767px;
$breakpoint_l: 1020px;
$breakpoint_xl: 1450px;