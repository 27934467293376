.loading-panel{
	width: 100vw;
	height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	color: $color_dark;

	svg{
		margin: 50px 0 0 0;
		g,path{
			stroke-width: 2px;
			// fill: $color_dark; 
			stroke: $color_dark;
		}
	}
}

.audio-loaders{
	position: fixed;
	z-index: 999999;
	width: 100vw;
	display: flex;

	p{
		margin: 10px auto;
		padding: 10px 15px;
		font-size: 14px;
		line-height: 21px;
		color: $color_dark;
		background: $color_messages;
		box-shadow: 0px 2px 6px rgba(0,0,0,.25);
	}
}

.user{
	position:fixed; 
	z-index: 9997;
	top:5px; 
	right:0px; 
	padding:8px; 
	background:$color_light;
	min-width: 20;
	cursor:pointer; 
	font-size:18px; 
	font-weight: 900;
	text-decoration: none;
	text-align:center;
	color: $color_dark;
}

.messages{
	position: fixed;
	top: 0;
	left: 0; 
	z-index: 9996;
	display: flex;
	width: 100vw;
	// height: 100vh;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	text-align: center;

	p{
		padding: 10px 15px;
		font-size: 14px;
		line-height: 21px;
		color: $color_dark;
		background: $color_messages;
		box-shadow: 0px 2px 6px rgba(0,0,0,.25);

		@include breakpoint( $breakpoint_s ) {
			width: 200px;
		}
	}

	button{
		margin: 0;
		padding: 0;
		background: none;
		border: none;
		font-family: Lexend Deca, sans-serif;
	}
}

.filters{
	position: fixed; 
	display: flex; 
	// width: calc( 100% - 10px);
	flex-direction: row; 
	justify-content: space-between;
	z-index: 9997;
	top: 5px; 
	right: 0px; 
	padding: 0 5px;

	&__categories{
		display: none;

		select{	
			border: none;
			padding: 5px;
			font-size: 13px;
			font-weight: 700;
			text-transform: uppercase;
		}
	}

	&__markers{
		display: none;
		/*display: flex;*/
		flex-direction: column;

		span{
			display: inline-block;
			max-width: 120px;
			margin: 1px 0 0 0;
			padding: 10px 13px;
			background: darken($color_area, 15%);
			color: #fff;
			font-size: 11px;
			font-weight: 700;
			cursor: pointer;
		}
	}

}

.actions{
	position: fixed; 
	display: flex; 
	width: calc( 100% - 10px);
	flex-direction: row; 
	justify-content: space-between;
	z-index: 9997;
	bottom: 5px; 
	right: 0px; 
	padding: 0 5px;
}

.go-back{
	padding: 8px; 
	min-width: 20px; 
	cursor: pointer; 
	font-size: 13px; 
	font-weight: 900;
	text-decoration: none;
	text-align: center;
	background: $color_dark;
	color: $color_light;
}
.actions__right{
	display: flex;
}

.where-am-i{

	padding: 8px;
	cursor: pointer;
	font-size: 13px; 
	font-weight: 700;
	text-transform: uppercase;

	background: $color_location; 
	color: $color_dark;

	&--active{
		background: $color_location;
	}

	svg{
		margin: 0 0 0 5px;
		rect{
			stroke: $color_dark; 
			fill: $color_dark;
		}
	}
}

.where-is-device{

	display: flex;
	padding: 8px;
	cursor: pointer;
	font-size: 13px; 
	font-weight: 700;
	text-transform: uppercase;

	background: $color_dark; 
	color: $color_device;

	&--active{
		background: $color_device;
		color: $color_dark;
	}

	svg{
		margin: 0 0 0 5px;
		rect{
			stroke: $color_dark; 
			fill: $color_dark;
		}
	}
}

.device-add{
	padding: 8px; 
	background: $color_light;
	color: $color_dark; 
	cursor: pointer; 
	font-size: 13px; 
	font-weight: 900;
	text-transform: uppercase;
}

.device-loading{
	/*
	position: fixed;
	z-index: 999;
	bottom: 12px;
	right: 14px;
	*/
	margin: 0 5px;
	width: 16px;
	height: 16px;
	display: flex;
	justify-content: center;
	align-items: center;

	svg{
		g,path{
			stroke-width: 8px;
			// fill: $color_dark; 
			stroke: $color_device;
		}
	}
}

.closest{
	padding: 8px;
	background: $color_area; 
	color: $color_light;
	cursor: pointer; 
	font-size: 13px; 
	font-weight: 700;
	text-transform: uppercase;
}

.qr-code{
	position: fixed;
	z-index: 10001;
	bottom: 100px;
	left: 0;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	&__wrapper{
		display: flex;
		flex-direction: column;
		padding: 20px; 
		background: $color_dark;
		color: $color_light;
		cursor: pointer; 
		font-size: 13px; 
		font-weight: 400;
		text-align: center;

		.qr-input[type="text"]{
			padding: 5px 10px;
			font-size: 18px;
			border: none;
		}
		.qr-submit{
			padding: 5px 12px;
			font-size: 18px;
			border: none;
			background: $color_device;
			color: $color_light;
			cursor: pointer;	
		}
	}

	&__btn{
		margin: 0 auto;
		padding: 8px;
		max-width: 150px;
		border: none;
		font-size: 13px;
		font-weight: 700;
		font-family: Radio Canada, sans-serif;
		text-transform: uppercase;
		cursor: pointer;

		&--zoom{
			background-color: $color_device;
			color: $color_light;
		}

		&--remove{
			margin: 25px auto 0 auto;
			background: $color_device;
			color: $color_light;
		}

		&--close{
			margin: 50px auto 0 auto;
			background: $color_light;
			color: $color_dark;
		}
	}
}

.animate-blink{
	margin: 0 0 0 5px;
	font-size: 30px;
	line-height: 0;
	vertical-align: middle;
}

.marker-cluster{
	
	width: 24px !important;
	height: 24px !important;

	display: flex;
	justify-content: center;
	align-items: center;

	border: 10px solid darken( $color_area, 10%);
	border-radius: 50%;
	background: $color_area;
	color: #fff;

	font-weight: 700;
	font-size: 13px;
}