.page--about{
	background-image: url( 'https://www.prijaton.com/img/posts.png');
	background-size: cover;
	background-position: center center;
	width: 100%;
	height: 100vh;
	overflow-y: auto;

	.about{
		margin: 50px 50px 0 50px;
		padding: 5px 15px; 
		color: $color_dark;

		width: 50vw;
		@include breakpoint( $breakpoint_m ) {
			width: 75vw;
		}
		@include breakpoint( $breakpoint_s ) {
			width: calc( 100vw - 130px );
		}

		&__head{
			margin: 50px 0 50px 0;
			font-size: 20px;
			line-height: 28px;

			a{
				font-weight: 700;
				color: $color_area;
			}
		}

		&-list{
			display: flex;
			flex-direction: column;
			
			width: 50vw;
			@include breakpoint( $breakpoint_l ) {
				width: 75vw;
			}
			@include breakpoint( $breakpoint_m ) {
				width: 70vw;
			}
			@include breakpoint( $breakpoint_s ) {
				width: calc( 100vw - 0px );
			}

			&__item{
				margin: 0;
				padding: 25px 50px;

				@include breakpoint( $breakpoint_m ) {
					padding: 25px 50px;
				}

				display: flex;
				flex-direction: row;
				align-items: flex-start;

				&--area{
					// background: $color_area;
					color: $color_dark;
				}
				&--location{
					// background: $color_location;
					color: $color_dark;
				}
				&--device{
					// background: $color_device;
					color: $color_dark;
				}
	
				& img{
					margin: 5px 20px 0 0;
				}
				& p{
					margin: 0;
					padding: 0;

					font-size: 16px; 
					line-height: 24px;

					small{
						font-weight: 400;
						color: #222;

						font-size: 13px; 
						line-height: 12px;
					}
				}
			}
		}

		&__explore{
			display: block;
			margin: 100px 50px;
			font-size: 20px; 
			line-height: 21px;
			letter-spacing: 1px;
			text-decoration: none;
			border: 0;
			cursor: pointer;
			position: relative;
			font-weight: 900;
			// color: $color_area;
			transition: all .1s ease-in-out;

			&:after{
				display: inline-block;
				width: 0px;
				overflow: hidden;
				content: '>';
				transition: all .1s ease-in-out;
				text-align: right;
			}

			&:hover{
				transform: translateX(5px);

				&:after{
					width: 20px;
				}
			}

			@include breakpoint( $breakpoint_m ) {
				text-align: center;
			}
		}
	}
}