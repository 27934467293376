@import url('https://fonts.googleapis.com/css?family=Lexend+Deca&display=swap');

body{
	font-family: Lexend Deca, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

a{
	text-decoration: none;
	color: $color_dark;
	font-weight: 400;
	text-decoration: none;
} 