.leaflet-container{

	height: 100vh;

	font-family: Lexend Deca, sans-serif;
	font-size: 12px;
}

.tooltip-popup{
	.leaflet-popup-content-wrapper,
	.leaflet-popup-tip{
		background: $color_area !important;
		color: $color_light !important;
		border-radius: 0;

		&:before{
			border-bottom-color: $color_area;
		}
	}
	a{
		color: $color_light;
		font-weight: 700;
		text-decoration: underline;
	}
}

.leaflet-tooltip{
	width: 150px;
	padding: 5px 15px;
	border: none;
	pointer-events: all;
	border-radius: 0;
	white-space: normal;

	h2,
	p{
		text-align: center;
	}

	&.tooltip--marker{
		background: $color_area;
		color: $color_light;

		&:before{
			border-bottom-color: $color_area;
		}
	}
	&.tooltip--location{
		background: $color_location;
		color: $color_dark;

		&:before{
			border-bottom-color: $color_location;
		}
	}
	&.tooltip--locator{
		background: $color_device;
		color: $color_dark;

		&:before{
			border-bottom-color: $color_device;
		}
	}
}

.leaflet-control-attribution{
	display: none;
}